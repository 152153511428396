import React from "react"
import { renderHTML } from "../../agility/agilityUtils"

const RichTextArea = ({ module }) => {
  // get module fields
  const { customFields } = module

  return (
    <div
      className="rich-text-area-module relative max-w-screen-md my-3 mx-auto"
      dangerouslySetInnerHTML={renderHTML(customFields.textblob)}
    />
  )
}

export default RichTextArea
